import React, { useState } from 'react';
import './FAQPage.css';
import { useTranslation } from 'react-i18next';
import Footer from '../Footer';

function FaqPage() {
  const [activeIndex, setActiveIndex] = useState(null);
  const { t } = useTranslation();

  const faqs = [
    {
      question: t('faq.questions.0.question'),
      answer: t('faq.questions.0.answer'),
    },
    {
      question: t('faq.questions.1.question'),
      answer: t('faq.questions.1.answer'),
    },
    {
      question: t('faq.questions.2.question'),
      answer: t('faq.questions.2.answer'),
    },
    {
      question: t('faq.questions.3.question'),
      answer: t('faq.questions.3.answer'),
    },
    {
      question: t('faq.questions.4.question'),
      answer: t('faq.questions.4.answer'),
    },
    {
      question: t('faq.questions.5.question'),
      answer: t('faq.questions.5.answer'),
    },
    {
      question: t('faq.questions.6.question'),
      answer: t('faq.questions.6.answer'),
    },
  ];

  const toggleFaq = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div>
      {/* Top Section consistent with About Us */}
      <section className="faq-page-section">
        <div className="faq-banner">
          <h1>{t('faq.pageTitle')}</h1>
        </div>
      </section>

      {/* FAQ Section */}
      <section className="faq-content">
        <div className="faq-container">
          {faqs.map((faq, index) => (
            <div
              key={index}
              className={`faq-item ${activeIndex === index ? "active" : ""}`}
              onClick={() => toggleFaq(index)}
            >
              <div className="faq-question">
                <h3>{faq.question}</h3>
                <span>{activeIndex === index ? "-" : "+"}</span>
              </div>
              <div className="faq-answer">
                <p>{faq.answer}</p>
              </div>
            </div>
          ))}
        </div>
      </section>
      <Footer />
    </div>
  );
}

export default FaqPage;
