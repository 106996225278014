import React from 'react';
import { useTranslation } from 'react-i18next';
import './AboutUsSection.css'; // Ensure you have the correct CSS file
import officeImage from './office.jpg'; // Adjust the path if necessary

const AboutUsSection = () => {
  const { t } = useTranslation();

  return (
    <div className="about-us-section">
      <div className="about-us-content" data-aos="fade-right">
        <h2>{t('home.aboutUsSection.title')}</h2>
        <p>{t('home.aboutUsSection.paragraph1')}</p>
        <p>{t('home.aboutUsSection.paragraph2')}</p>
        <p>{t('home.aboutUsSection.paragraph3')}</p>
        <a href="about" className="read-more-link">
          {t('home.aboutUsSection.readMore')}
          <span className="arrow">→</span>
        </a>
      </div>
      <div className="about-us-image" data-aos="fade-left">
        <img src={officeImage} alt="Company Building" />
      </div>
    </div>
  );
};

export default AboutUsSection;
