import React, { useEffect } from 'react';
import { Route, Routes, useLocation, useParams, Navigate } from 'react-router-dom';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useTranslation } from 'react-i18next';
import CookieConsent, { Cookies } from "react-cookie-consent";
import HomePage from './HomePage/HomePage';
import AboutUs from './AboutUsPage/AboutUs';
import Services from './ServicesPage/Services';
import Blog from './BlogPage/Blog';
import ContactUs from './ContactUsPage/ContactUs';
import Catalog from './Catalog/Catalog';
import FAQ from './FAQPage/FAQPage';
import NavigationBar from './NavigationBar';
import ReactGA from 'react-ga4';
import PrivacyPolicy from './PrivacyPolicyPage/PrivacyPolicy';
import './CookieBanner.css'; // Ensure this import is present to apply your CSS
import TermsOfService from './TermsOfServicePage/TermsOfServices';

function LanguageWrapper({ children }) {
  const { i18n } = useTranslation();
  const { lng } = useParams();

  useEffect(() => {
    const language = lng || 'pl'; // Default language is Polish (pl)
    if (i18n.language !== language) {
      i18n.changeLanguage(language);
    }
  }, [lng, i18n]);

  return children;
}

function TrackPageViews() {
  const location = useLocation();

  useEffect(() => {
    if (Cookies.get('ga-consent') === 'true') {
      ReactGA.send({ hitType: 'pageview', page: location.pathname });
    }
  }, [location]);

  return null;
}

function App() {
  const { t } = useTranslation();

  useEffect(() => {
    AOS.init({
      duration: 1200,
      easing: 'ease-in-out',
      offset: 200,
      delay: 100,
      once: true,
    });
  }, []);

  return (
    <div className="App">
      <CookieConsent
        onAccept={() => {
          Cookies.set('ga-consent', 'true', { expires: 365 });
          ReactGA.initialize('G-4HMDJC7L64');
        }}
        onDecline={() => {
          Cookies.set('ga-consent', 'false', { expires: 365 });
        }}
        enableDeclineButton
        declineButtonText={t('cookieBanner.decline')}
        buttonText={t('cookieBanner.accept')}
        cookieName="ga-consent"
        containerClasses="cookie-banner" // Apply your custom styles to the banner
        buttonClasses="cookie-banner__button" // Apply your custom styles to the accept button
        declineButtonClasses="cookie-banner__decline-button" // Apply your custom styles to the decline button
      >
        {t('cookieBanner.message')}
      </CookieConsent>
      <TrackPageViews />
      <NavigationBar />
      <Routes>
        {/* Redirect root URL to default language /home */}
        <Route 
          path="/" 
          element={<Navigate to={`/pl/home`} replace />} 
        />
        <Route path="/:lng/home" element={<HomePage />} />
        <Route path="/:lng/about" element={<AboutUs />} />
        <Route path="/:lng/services" element={<Services />} />
        <Route path="/:lng/blog" element={<Blog />} />
        <Route path="/:lng/contact" element={<ContactUs />} />
        <Route path="/:lng/catalog" element={<Catalog />} />
        <Route path="/:lng/faq" element={<FAQ />} />
        <Route path="/:lng/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/:lng/terms-of-service" element={<TermsOfService />} />
        {/* Catch-all route */}
        <Route path="*" element={<Navigate to={`/pl/home`} replace />} />
      </Routes>
    </div>
  );
}

export default App;
