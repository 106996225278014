import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import enTranslations from './locales/en/translation.json';
import plTranslations from './locales/pl/translation.json';

i18n
  .use(LanguageDetector) // Use the language detector
  .use(initReactI18next) // Passes i18n down to react-i18next
  .init({
    resources: {
      en: {
        translation: enTranslations,
      },
      pl: {
        translation: plTranslations,
      },
    },
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false, // React already safes from xss
    },
    detection: {
      // Order and from where user language should be detected
      order: ['path', 'localStorage', 'navigator'],

      // Keys or params to lookup language from
      lookupFromPathIndex: 0, // Look at the first part of the path to detect language

      // Cache user language
      caches: ['localStorage'],
    },
  });

export default i18n;