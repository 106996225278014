import React from 'react';
import './Catalog.css';
import Footer from '../Footer';
import { useTranslation } from 'react-i18next';

function CatalogueComingSoon() {
  const { t } = useTranslation();

  return (
    <div>
      <section className="catalogue-coming-soon-section">
        {/* Coming Soon Section */}
        <div className="coming-soon-content">
          <h1>{t('catalogueComingSoon.sectionTitle')}</h1>
          <h2>{t('catalogueComingSoon.comingSoonTitle')}</h2>
          <p>{t('catalogueComingSoon.comingSoonText1')}</p>
          <p>{t('catalogueComingSoon.comingSoonText2')}</p>
        </div>

        {/* Additional Information Section */}
        <div className="additional-info">
          <h3>{t('catalogueComingSoon.additionalInfoTitle')}</h3>
          <ul>
            {t('catalogueComingSoon.additionalInfoList', { returnObjects: true }).map((item, index) => (
              <li key={index}>{item}</li>
            ))}
          </ul>
        </div>
      </section>
      <Footer />
    </div>
  );
}

export default CatalogueComingSoon;
