import React from 'react';
import Slider from 'react-slick';
import { useTranslation } from 'react-i18next';
import './FabricSliderSection.css';

// Import the images
import textileImage1 from './textile-image-1.jpeg';
import textileImage2 from './textile-image-2.jpg';
import textileImage3 from './textile-image-3.jpeg';
import textileImage4 from './textile-image-4.jpg';

// Import Slick Carousel's CSS
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const FabricSliderSection = () => {
  const { t } = useTranslation();

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          dots: true,
          arrows: false, // Example of disabling arrows on mobile
        },
      },
    ],
  };

  return (
    <div className="fabric-slider-section">
      <div className="slider-container" data-aos="fade-right">
        <Slider {...settings}>
          <div>
            <img
              src={textileImage1}
              alt={t('home.fabricSliderSection.textile1Alt')}
              className="fabric-image"
            />
          </div>
          <div>
            <img
              src={textileImage2}
              alt={t('home.fabricSliderSection.textile2Alt')}
              className="fabric-image"
            />
          </div>
          <div>
            <img
              src={textileImage3}
              alt={t('home.fabricSliderSection.textile3Alt')}
              className="fabric-image"
            />
          </div>
          <div>
            <img
              src={textileImage4}
              alt={t('home.fabricSliderSection.textile4Alt')}
              className="fabric-image"
            />
          </div>
        </Slider>
      </div>
      <div className="text-section" data-aos="fade-left">
        <h2>{t('home.fabricSliderSection.title')}</h2>
        <p>{t('home.fabricSliderSection.paragraph1')}</p>
        <p>
          {t('home.fabricSliderSection.paragraph2')}
          <ul>
            {t('home.fabricSliderSection.list', { returnObjects: true }).map((item, index) => (
              <li key={index}>{item}</li>
            ))}
          </ul>
          {t('home.fabricSliderSection.newsletter')}
        </p>
        <a href="catalog" className="read-more-link">
          {t('home.fabricSliderSection.discoverCatalog')} <span className="arrow">→</span>
        </a>
      </div>
    </div>
  );
};

export default FabricSliderSection;
