import React, { useState } from 'react';
import axios from 'axios';
import './ContactUs.css';
import Footer from '../Footer';
import { useTranslation } from 'react-i18next';

function ContactUs() {
  const [submitted, setSubmitted] = useState(false);
  const { t } = useTranslation();

  const handleSubmit = (event) => {
    event.preventDefault();

    // Collecting form data
    const formData = {
      name: event.target[0].value,
      email: event.target[1].value,
      message: event.target[2].value,
      _subject: `Wiadomość od ${event.target[0].value} – Formularz Kontaktowy`,
    };

    // Sending form data to Formspree
    axios({
      method: 'POST',
      url: 'https://formspree.io/f/mblrbgwq', // Replace with your Formspree endpoint
      data: formData,
      headers: {
        'Accept': 'application/json',
      },
    })
      .then((response) => {
        if (response.status === 200) {
          setSubmitted(true);
          setTimeout(() => {
            // Reset the form fields only after a successful submission
            event.target.reset();
            setSubmitted(false);
          }, 3000); // Reset the form after 3 seconds
        } else {
          console.error('Form submission failed:', response.statusText);
        }
      })
      .catch((error) => {
        console.error('Error submitting the form:', error);
      });
  };

  return (
    <div>
      <section className="contact-page-section">
        <div className="contact-us-content">
          <h1>{t('contact.pageTitle')}</h1>
          {/* You can add more content here if needed */}
        </div>
      </section>
      
      <section>
        <div className="contact-us-container">
          <div className="contact-content">
            <div className="contact-info">
              <h1>{t('contact.companyName')}</h1>
              <p dangerouslySetInnerHTML={{ __html: t('contact.address') }} />
              <h3>{t('contact.openingHoursTitle')}</h3>
              <p dangerouslySetInnerHTML={{ __html: t('contact.openingHours') }} />
              <div className="contact-methods">
                <p><i className="fas fa-phone"></i><a href={`tel:${t('contact.phone1')}`}>{t('contact.phone1')}</a></p>
                <p><i className="fas fa-phone"></i><a href={`tel:${t('contact.phone2')}`}>{t('contact.phone2')}</a></p>
                <p><i className="fas fa-envelope"></i> <a href={`mailto:${t('contact.email1')}`}>{t('contact.email1')}</a></p>
                <p><i className="fas fa-envelope"></i> <a href={`mailto:${t('contact.email2')}`}>{t('contact.email2')}</a></p>
              </div>
            </div>

            <div className="contact-form">
              <h2>{t('contact.formTitle')}</h2>
              <form onSubmit={handleSubmit} className={submitted ? "submitted" : ""}>
                <input type="text" placeholder={t('contact.formNamePlaceholder')} required />
                <input type="email" placeholder={t('contact.formEmailPlaceholder')} required />
                <textarea placeholder={t('contact.formMessagePlaceholder')} required></textarea>
                <div className="consent">
                  <input type="checkbox" id="consent" required />
                  <label htmlFor="consent">
                    {t('contact.consentText')}
                  </label>
                </div>
                <button type="submit">{t('contact.submitButtonText')}</button>
                {submitted && <p className="form-submitted">{t('contact.submissionConfirmation')}</p>}
              </form>
            </div>
          </div>

          {/* Embedded Google Maps */}
          <div className="google-maps">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2474.886251541972!2d19.44609817734432!3d51.661918971848934!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x471a37929f6198bf%3A0xfc3c7dc5b7e5d288!2sPanache%20Fashion!5e0!3m2!1sen!2spl!4v1724782073375!5m2!1sen!2spl"
              width="100%"
              height="450"
              frameBorder="0"
              style={{ border: 0 }}
              allowFullScreen=""
              aria-hidden="false"
              tabIndex="0">
            </iframe>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
}

export default ContactUs;
