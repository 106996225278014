import React from 'react';
import './Blog.css';
import Footer from '../Footer';
import { useTranslation } from 'react-i18next';

function BlogComingSoon() {
  const { t } = useTranslation();

  return (
    <div>
      {/* Blog Section Header */}
      <section className="blog-page-section">
        <div className="blog-content">
          <h1>{t('blog.pageTitle')}</h1>
        </div>
      </section>

      {/* Coming Soon Section */}
      <section className="coming-soon-section">
        <div className="coming-soon-content">
          <h2>{t('blog.comingSoonTitle').toUpperCase()}</h2>
          <p>{t('blog.comingSoonDescription')}</p>
        </div>
      </section>
      <Footer />
    </div>
  );
}

export default BlogComingSoon;
