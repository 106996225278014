import React from 'react';
import './PrivacyPolicy.css'; // Import the CSS file for styling
import { useTranslation } from 'react-i18next'; // Import i18n hook
import Footer from '../Footer';

const PrivacyPolicy = () => {
  const { t } = useTranslation(); // Use the translation hook

  return (
    <div>
      {/* Privacy Policy Banner Section */}
      <section className="privacy-policy-page-section">
        <div className="privacy-policy-banner-content">
          <h1>{t('privacyPolicy.bannerTitle')}</h1>
        </div>
      </section>

      {/* Privacy Policy Content Section */}
      <section className="privacy-policy-section">
        <div className="privacy-policy-content">
          <p>{t('privacyPolicy.effectiveDate')}</p>
          <h2>{t('privacyPolicy.section1Title')}</h2>
          <p>{t('privacyPolicy.section1Content')}</p>
          <h2>{t('privacyPolicy.section2Title')}</h2>
          <p>{t('privacyPolicy.section2Content')}</p>
          <ul>
            <li>{t('privacyPolicy.listItem1')}</li>
            <li>{t('privacyPolicy.listItem2')}</li>
            <li>{t('privacyPolicy.listItem3')}</li>
            <li>{t('privacyPolicy.listItem4')}</li>
          </ul>
          <h2>{t('privacyPolicy.section3Title')}</h2>
          <p>{t('privacyPolicy.section3Content')}</p>
          <ul>
            <li>{t('privacyPolicy.listItem5')}</li>
            <li>{t('privacyPolicy.listItem6')}</li>
            <li>{t('privacyPolicy.listItem7')}</li>
            <li>{t('privacyPolicy.listItem8')}</li>
          </ul>
          <h2>{t('privacyPolicy.section4Title')}</h2>
          <p>{t('privacyPolicy.section4Content')}</p>
          <ul>
            <li>{t('privacyPolicy.listItem9')}</li>
            <li>{t('privacyPolicy.listItem10')}</li>
            <li>{t('privacyPolicy.listItem11')}</li>
          </ul>
          <h2>{t('privacyPolicy.section5Title')}</h2>
          <p>{t('privacyPolicy.section5Content')}</p>
          <ul>
            <li>{t('privacyPolicy.listItem12')}</li>
            <li>{t('privacyPolicy.listItem13')}</li>
          </ul>
          <h2>{t('privacyPolicy.section6Title')}</h2>
          <p>{t('privacyPolicy.section6Content')}</p>
          <h2>{t('privacyPolicy.section7Title')}</h2>
          <p>{t('privacyPolicy.section7Content')}</p>
          <h2>{t('privacyPolicy.section8Title')}</h2>
          <p>{t('privacyPolicy.section8Content')}</p>
          <ul>
            <li>{t('privacyPolicy.listItem14')}</li>
            <li>{t('privacyPolicy.listItem15')}</li>
            <li>{t('privacyPolicy.listItem16')}</li>
            <li>{t('privacyPolicy.listItem17')}</li>
            <li>{t('privacyPolicy.listItem18')}</li>
            <li>{t('privacyPolicy.listItem19')}</li>
          </ul>
          <h2>{t('privacyPolicy.section9Title')}</h2>
          <p>{t('privacyPolicy.section9Content')}</p>
          <h2>{t('privacyPolicy.section10Title')}</h2>
          <p>{t('privacyPolicy.section10Content')}</p>
          <p dangerouslySetInnerHTML={{ __html: t('privacyPolicy.contactInfo') }} />
        </div>
      </section>

      <Footer />
    </div>
  );
}

export default PrivacyPolicy;
