import React from 'react';
import './TermsOfService.css'; // Import the CSS file for styling
import { useTranslation } from 'react-i18next'; // Import i18n hook
import Footer from '../Footer';

const TermsOfService = () => {
  const { t } = useTranslation(); // Use the translation hook

  return (
    <div>
      {/* Terms of Service Banner Section */}
      <section className="terms-of-service-page-section">
        <div className="terms-of-service-banner-content">
          <h1>{t('termsOfService.pageTitle')}</h1>
        </div>
      </section>

      {/* Terms of Service Content Section */}
      <section className="terms-of-service-section">
        <div className="terms-of-service-content">
          <p>{t('termsOfService.effectiveDate')}</p>
          
          <section>
            <h2>{t('termsOfService.introTitle')}</h2>
            <p>{t('termsOfService.introText')}</p>
          </section>
          
          <section>
            <h2>{t('termsOfService.servicesTitle')}</h2>
            <p>{t('termsOfService.servicesText')}</p>
          </section>
          
          <section>
            <h2>{t('termsOfService.accountTitle')}</h2>
            <p>{t('termsOfService.accountText')}</p>
          </section>
          
          <section>
            <h2>{t('termsOfService.userRightsTitle')}</h2>
            <p>{t('termsOfService.userRightsText')}</p>
          </section>
          
          <section>
            <h2>{t('termsOfService.intellectualPropertyTitle')}</h2>
            <p>{t('termsOfService.intellectualPropertyText')}</p>
          </section>
          
          <section>
            <h2>{t('termsOfService.liabilityTitle')}</h2>
            <p>{t('termsOfService.liabilityText')}</p>
          </section>
          
          <section>
            <h2>{t('termsOfService.changesTitle')}</h2>
            <p>{t('termsOfService.changesText')}</p>
          </section>
          
          <section>
            <h2>{t('termsOfService.lawTitle')}</h2>
            <p>{t('termsOfService.lawText')}</p>
          </section>
          
          <section>
            <h2>{t('termsOfService.contactTitle')}</h2>
            <p dangerouslySetInnerHTML={{ __html: t('termsOfService.contactText') }}></p>
          </section>
        </div>
      </section>

      <Footer />
    </div>
  );
}

export default TermsOfService;
