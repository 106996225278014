import React from 'react';
import { useTranslation } from 'react-i18next'; // Import useTranslation
import './OurSellersSection.css';

// Import the image for the right side
import sampleSwatchesImage from './textile-image-4.jpg';

// Import the icons (You can use an icon library like FontAwesome or any other)
import { FaUserTie, FaGlobe, FaUsers } from 'react-icons/fa';

const OurSellersSection = () => {
  const { t } = useTranslation(); // Initialize translation hook

  return (
    <div className="our-sellers-section">
      <div className="text-content" data-aos="fade-right">
        <h2>{t('home.ourSellersSection.title')}</h2>
        <div className="selling-point">
          <FaUserTie className="icon" />
          <div>
            <h3>{t('home.ourSellersSection.point1.title')}</h3>
            <p>{t('home.ourSellersSection.point1.description')}</p>
          </div>
        </div>
        <div className="selling-point">
          <FaUsers className="icon" />
          <div>
            <h3>{t('home.ourSellersSection.point2.title')}</h3>
            <p>{t('home.ourSellersSection.point2.description')}</p>
          </div>
        </div>
        <div className="selling-point">
          <FaGlobe className="icon" />
          <div>
            <h3>{t('home.ourSellersSection.point3.title')}</h3>
            <p>{t('home.ourSellersSection.point3.description')}</p>
          </div>
        </div>
        <a href="about" className="read-more-link">
          {t('home.ourSellersSection.readMore')} <span className="arrow">→</span>
        </a>
      </div>
      <div className="image-content" data-aos="fade-left">
        <img src={sampleSwatchesImage} alt={t('home.ourSellersSection.imageAlt')} />
      </div>
    </div>
  );
};

export default OurSellersSection;
