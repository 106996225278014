import React from 'react';
import { useTranslation } from 'react-i18next';
import './Footer.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faFacebookF, faInstagram, faLinkedinIn, faWhatsapp } from '@fortawesome/free-brands-svg-icons';

const Footer = () => {
  const { t } = useTranslation();

  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-section company-info compact-section">
          <h3>Panache Fashion</h3>
          <p>NIP: 7312048227</p>
          <p>Gospodarz 40A</p>
          <p>95-030 Rzgów</p>
        </div>
        <div className="footer-section opening-hours compact-section">
          <h3>{t('footer.openingHours.title')}</h3>
          <p>{t('footer.openingHours.weekdays')}</p>
          <p>{t('footer.openingHours.saturday')}</p>
        </div>
        <div className="footer-section menu">
          <h3>{t('footer.menu.title')}</h3>
          <ul>
            <li><a href="about">{t('footer.menu.aboutUs')}</a></li>
            <li><a href="services">{t('footer.menu.services')}</a></li>
            <li><a href="contact">{t('footer.menu.contact')}</a></li>
            <li><a href="catalog">{t('footer.menu.catalog')}</a></li>
            <li><a href="blog">{t('footer.menu.blog')}</a></li>
            <li><a href="faq">{t('footer.menu.faq')}</a></li>
          </ul>
        </div>
        <div className="footer-section contact-info compact-section">
          <h3>{t('footer.contactInfo.title')}</h3>
          <div className="contact-item">
            <FontAwesomeIcon icon={faPhone} />
            <p><a href="tel:+48508403956">+48 508 403 956</a></p>
          </div>
          <div className="contact-item">
            <FontAwesomeIcon icon={faEnvelope} />
            <p><a href="mailto:panache@panachefashion.pl">panache@panachefashion.pl</a></p>
          </div>
        </div>
        <div className="footer-section social-media compact-section">
          <h3>{t('footer.socialMedia.title')}</h3>
          <div className="social-icons">
            <a href="https://www.facebook.com/panachefashionpl/"><FontAwesomeIcon icon={faFacebookF} /></a>
            <a href="https://www.instagram.com/panachefashionpl/"><FontAwesomeIcon icon={faInstagram} /></a>
            <a href="https://www.linkedin.com/company/panache-fashion-sp-z-o-o/"><FontAwesomeIcon icon={faLinkedinIn} /></a>
            <a href="https://wa.me/+48508403956"><FontAwesomeIcon icon={faWhatsapp} /></a>
          </div>
        </div>
      </div>
      <div className="footer-bottom">
        <p>
          © 2024 Panache Fashion /
          <a href="terms-of-service">{t('footer.bottom.legal')}</a> /
          <a href="privacy-policy">{t('footer.bottom.privacy')}</a>
        </p>
      </div>
    </footer>
  );
};

export default Footer;
