import React from 'react';
import { useTranslation } from 'react-i18next';
import './AboutUs.css';
import BuildingImage from '../HomePage/office.jpg'; // Adjust this path as needed
import FabricImage from './about-us-page-2.jpg'; // Path to the new fabric image
import TeamMember1 from './team-member-1.jpeg'; // Path to team member 1's image
import PlaceholderImage from './Person_Image_Placeholder.png';
import Footer from '../Footer';

function AboutUs() {
  const { t } = useTranslation();

  return (
    <div>
      {/* Existing About Us Section */}
      <section className="about-us-page-section">
        <div className="about-us-content">
          <h1>{t('aboutUs.pageTitle')}</h1>
          {/* You can add more content here if needed */}
        </div>
      </section>

      {/* Existing About Us Section with Image on the Left and Text on the Right */}
      <section className="about-us-section">
        <div className="about-us-image" data-aos="fade-right">
          <img src={BuildingImage} alt={t('aboutUs.companyTitle')} />
        </div>
        <div className="about-us-content" data-aos="fade-left">
          <h2>{t('aboutUs.companyTitle')}</h2>
          <p>{t('aboutUs.companyDescription1')}</p>
          <p>{t('aboutUs.companyDescription2')}</p>
          <p>{t('aboutUs.companyDescription3')}</p>
        </div>
      </section>

      {/* New Section with Text on the Left and Image on the Right */}
      <section className="about-us-journey-section">
        <div className="about-us-journey-content">
          <h2>{t('aboutUs.journeyTitle')}</h2>
          <p>{t('aboutUs.journeyDescription1')}</p>
          <p>{t('aboutUs.journeyDescription2')}</p>
          <p>{t('aboutUs.journeyDescription3')}</p>
        </div>
        <div className="about-us-journey-image">
          <img src={FabricImage} alt="Fabric Display" />
        </div>
      </section>

      {/* New "Our Team" Section */}
      <section className="about-us-team-section">
        <div className="about-us-team-images">
          <div className="team-member">
            <img src={TeamMember1} alt="Team Member 1" />
            <p className="team-member-name">Pradeep Meharchandani</p>
            <p className="team-member-title">{t('aboutUs.teamPosition1')}</p>
          </div>
          <div className="team-member">
            <img src={PlaceholderImage} alt="Team Member 2" />
            <p className="team-member-name">Barbara Masłowska</p>
            <p className="team-member-title">{t('aboutUs.teamPosition2')}</p>
          </div>
          {/* <div className="team-member">
            <img src={TeamMember3} alt="Team Member 3" />
            <p className="team-member-name">Michał Wiśniewski</p>
            <p className="team-member-title">Operations Manager</p>
          </div> */}
          {/* Add more team members as needed */}
        </div>
        <div className="about-us-team-content">
          <h2>{t('aboutUs.teamTitle')}</h2>
          <p>{t('aboutUs.teamDescription1')}</p>
          <p>{t('aboutUs.teamDescription2')}</p>
        </div>
      </section>

      <Footer />
    </div>
  );
}

export default AboutUs;
