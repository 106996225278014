import React from 'react';
import { useTranslation } from 'react-i18next';
import './HomePage.css'; // Import the CSS file for styling
import AboutUsSection from './AboutUsSection';
import Footer from '../Footer'; // Import the Footer component
import FabricSliderSection from './FabricSliderSection';
import OurSellersSection from './OurSellersSection';

const HomePage = () => {
  const { t } = useTranslation();

  return (
    <div>
      {/* Hero Section */}
      <div className="hero-section" data-aos="fade-up">
        <div className="overlay-text">
          <h1 data-aos="zoom-in" dangerouslySetInnerHTML={{ __html: t('home.heroSection.title') }} />
        </div>
      </div>

      {/* About Us Section */}
      <AboutUsSection />

      {/* Fabric Types Section */}
      <FabricSliderSection />

      {/* Our Sellers Section */}
      <OurSellersSection />

      {/* Footer Section */}
      <Footer /> {/* Adding the Footer component here */}
    </div>
  );
};

export default HomePage;
