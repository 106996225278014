import React from 'react';
import { Navbar, Nav, Container, Dropdown } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import logo from './panache_logo.png';
import './NavigationBar.css';

function NavigationBar() {
  const { i18n, t } = useTranslation();
  const navigate = useNavigate();
  const { lng } = useParams();

  // Use the current language from URL or fallback to the i18n language setting
  const currentLang = lng || i18n.language;

  const handleSelectLanguage = (language) => {
    i18n.changeLanguage(language);
    const currentPath = window.location.pathname.split('/').slice(2).join('/');
    navigate(`/${language}/${currentPath}`);
  };

  return (
    <Navbar bg="light" expand="lg" className="custom-navbar">
      <Container>
        <Navbar.Brand href={`/${currentLang}/home`} className="d-flex align-items-center">
          <img
            src={logo}
            height="60"
            className="d-inline-block align-top navbar-logo"
            alt="Panache Fashion logo"
          />
          <span className="navbar-brand-text cursive-text">Panache Fashion</span>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto nav-links-left">
            <Nav.Link href={`/${currentLang}/about`}>{t('navbar.about')}</Nav.Link>
            <Nav.Link href={`/${currentLang}/services`}>{t('navbar.services')}</Nav.Link>
            <Nav.Link href={`/${currentLang}/blog`}>{t('navbar.blog')}</Nav.Link>
            <Nav.Link href={`/${currentLang}/contact`}>{t('navbar.contact')}</Nav.Link>
          </Nav>
          <Nav className="nav-links-right">
            <Nav.Link href={`/${currentLang}/catalog`}>{t('navbar.catalog')}</Nav.Link>
            <Dropdown className="language-switcher">
              <Dropdown.Toggle variant="link" id="dropdown-basic">
                {currentLang === 'pl' ? t('navbar.pl') : t('navbar.en')}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item onClick={() => handleSelectLanguage('pl')}>
                  {t('navbar.pl')}
                </Dropdown.Item>
                <Dropdown.Item onClick={() => handleSelectLanguage('en')}>
                  {t('navbar.en')}
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavigationBar;